import $ from 'jquery';
import 'slick-carousel';
import { eq } from 'semver';
import modal from './modal';

global.jQuery = require('jquery');
global.$ = require('jquery');

$(function() {
  modal();
  const $window = $(window);
  const $body = $('body');
  const $header = $('.header');
  const $goTop = $('.go-top');
  let isMobile;
  let isDesktop;
  let circleType;

  function detectDevice() {
    const md = new MobileDetect(window.navigator.userAgent);
    isMobile = () => {
      const device = md.mobile() || md.tablet();
      return device && device.length > 0;
    };
    isDesktop = () => !isMobile();
    if (isMobile()) {
      $body.addClass('hover-off touch-on');
    } else {
      $body.addClass('hover-on touch-off');
    }
  }
  detectDevice();

  $window.on('resize', () => {
    detectDevice();
  });

  $('.menu-btn2').on('click', function() {
    $('.navigation').addClass('js-active');
  });

  $('.menu-btn2-closer').on('click', function() {
    $('.navigation').removeClass('js-active');
  });

  (function() {
    const $dropdown = $('.dropdown');
    if (isMobile()) {
      $dropdown.on('click touch', function() {
        $(this).toggleClass('js-active');
      });
    }
  })();

  $window.on('scroll', () => {
    initHeader();
    initGoTop();
  });

  $window.bind('mousewheel', function(event) {
    if (event.originalEvent.wheelDelta >= 0) {
      $header.removeClass('bot');
      $header.addClass('bot');
    } else {
      $header.removeClass('bot');
      $header.addClass('top');
    }
  });

  function initHeader() {
    const offset = window.pageYOffset;
    if (offset > 0) {
      $header.addClass('js-sticky');
    } else {
      $header.removeClass('js-sticky');
    }
  }
  initHeader();

  function tabs() {
    const tabs = $('.tabs');
    const $tabList = tabs.find('.tabs-list-item');
    const $tabs = tabs.find('.tabs-container-tab');
    $($tabList[0]).addClass('js-active');
    $($tabs[0]).addClass('js-active');
    $tabList.on('click', function() {
      $tabList.removeClass('js-active');
      $tabs.removeClass('js-active');
      $(this).addClass('js-active');
      $($tabs[$.inArray(this, $tabList)]).addClass('js-active');
    });
  }
  tabs();

  const $tabNext = $('.tab-next');
  const $tabPrev = $('.tab-prev');

  const getTabBtnsElements = () => {
    const tabs = $('.tabs');
    const $tabList = tabs.find('.tabs-list-item');
    const $tabs = tabs.find('.tabs-container-tab');
    const $activeTabList = tabs.find('.tabs-list-item.js-active');
    const index = $tabList.index($activeTabList);
    return {
      index,
      $tabs,
      $tabList
    };
  };
  $tabNext.on('click', () => {
    const { index, $tabs, $tabList } = getTabBtnsElements();
    $tabList.removeClass('js-active');
    $tabs.removeClass('js-active');
    if (index === $tabList.length - 1) {
      $('.tabs-list-item:eq(0)').addClass('js-active');
      $('.tabs-container-tab:eq(0)').addClass('js-active');
    } else {
      $(`.tabs-list-item:eq(${index + 1})`).addClass('js-active');
      $(`.tabs-container-tab:eq(${index + 1})`).addClass('js-active');
    }
  });

  $tabPrev.on('click', () => {
    const { index, $tabs, $tabList } = getTabBtnsElements();
    $tabList.removeClass('js-active');
    $tabs.removeClass('js-active');
    $(`.tabs-list-item:eq(${index - 1})`).addClass('js-active');
    $(`.tabs-container-tab:eq(${index - 1})`).addClass('js-active');
  });

  $('#slider').slick({
    dots: true,
    buttons: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 895,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.header-nav-item a').on('click', function(e) {
    if ($(this).parent().hasClass('link-stock-btn')) {
      return;
    }

    e.preventDefault();
    const id = $(this).attr('href');
    const headerHeight = $('header').outerHeight(true);
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(id).offset().top - headerHeight
      },
      1500
    );
  });

  $('.menu-btn').on('click', function() {
    if ($(this).hasClass('is-active')) {
      $(this).removeClass('is-active');
      $('.header').removeClass('is-menu-open');
    } else {
      $(this).addClass('is-active');
      $('.header').addClass('is-menu-open');
    }
  });

  $('body').on('click', function(e) {
    if (
      $(e.target).closest('.header-nav').length ||
      $(e.target).closest('.header-nav a').length ||
      $(e.target).closest('.hamburger').length
    )
      return;
    $('.header').removeClass('is-menu-open');
    $('.menu-btn').removeClass('is-active');
  });

  const initGoTop = () => {
    const offset = window.pageYOffset;
    if (offset > 500) {
      $goTop.show();
    } else {
      $goTop.hide();
    }
  };

  $goTop.on('click', () => {
    $header.removeClass('bot top');
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: 0
      },
      1500
    );
  });

  $("#register-input").mask("99-99-99-99-99");
  $('.mask-phone').mask("+38 (999) 99-99-999");

  $(".fb-btn-open").on('click', () => {
    $(".fb-button a").trigger('click');
    return false;
  });

  $(".reg-btn-open").on('click', () => {
    $("button[data-modal='registration']").trigger('click');
    return false;
  });

});
