//modals

const modal = () => {
  const $overlay = $('.overlay'),
    $modalCloser = $('.modal-closer');

  $('[data-modal]').on('click', function(e) {
    const modalID = '#' + $(this).attr('data-modal');
    if (modalID !== undefined) {
      e.preventDefault();
      closeModal();
      showModal($(modalID));
    }
  });

  $modalCloser.on('click', function() {
    $(this)
      .parent()
      .parent()
      .removeClass('active')
      .css('top', '');
    hideOverlay();
  });

  $overlay.on('click', function(e) {
    if ($(e.target).closest('modal').length) return;
    $('.modal.active')
      .removeClass('active')
      .css('top', '');
    $('.catalog-box.active');
    closeModal();
    hideOverlay();
  });

  const showOverlay = () => $overlay.addClass('active');
  const hideOverlay = () => {
    setTimeout(() => {
      $overlay.removeClass('active');
    }, 300);
  };

  function showModal($modal) {
    $modal.css('height', 'auto');
    const modalHeight = $modal.outerHeight(true),
      $modalHead = $modal.find('.modal-head'),
      $modalBody = $modal.find('.modal-body'),
      windowHeight = $(window).height();
    let offsetTop = 0,
      modalBodyHeight = 0;
    if (modalHeight > windowHeight) {
      offsetTop = 10;
      //modalBodyHeight = windowHeight - offsetTop * 2 - $modalHead.outerHeight(true);
      //$modal.css('height', windowHeight - offsetTop * 2);
        $modal.css('height', windowHeight - offsetTop * 2).css('overflow-y', 'scroll');
    } else {
      offsetTop = (windowHeight - modalHeight) / 2;
    }
    showOverlay();
    $modal.css('top', offsetTop).addClass('active');
  }

  const closeModal = () =>
    $('.modal.active')
      .removeClass('active')
      .css('top', '');

  $(window).resize(function() {
    const $modal = $('.modal.active');

    if ($modal.length) {
        showModal($modal);
    }
  });

};

export default modal;
